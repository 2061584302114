:root {
  --night: #0F0E0E;
  --claret: #7B0823;
  --jtc: #522B47;
  --cambridge: #8DAA9D;
  --lowcambridge: #c7eedd77;
  --snow: #FBF5F3;
  --alerts: rgb(36, 59, 85);
  --success: #28a745;
  --danger: #dc3545;
}

@font-face {
  font-family: 'soft';
  src: url('../assets/fonts/made_carving_soft/MADECarvingSoftPERSONALUSE-Regular.otf') format('truetype');
}

* {
  box-sizing: border-box;
  font-family: 'soft', Courier, monospace;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--night);
  margin: 0px;
  padding: 5px;
}

h1 {
  font-size: 1.8vw;
  font-weight: bold;
  color: var(--cambridge);
  margin: 0px;
}

h2 {
  font-size: 1.6vw;
  font-weight: bold;
  color: var(--cambridge);
  margin: 0px;
}

h3 {
  font-size: 1.4vw;
  font-weight: bold;
  color: var(--cambridge);
  margin: 0px;
}

h4 {
  font-size: 1.2vw;
  font-weight: bold;
  color: var(--cambridge);
  margin: 0px;
}


p,
div,
a {
  font-size: 1vw;
}

p {
  color: var(--snow);
}

a {
  text-decoration: none;
  color: var(--claret);
  transition: 300ms;
}

/* menu */
.cabecera {
  position: sticky;
  top: 0;
  display: flex;
  height: 50px;
  padding: 5px;
  overflow: hidden;
  z-index: 10;
}

.cabecera.header-scrolled {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(78, 77, 77, 0.607);
  /* Ajusta el color y la transparencia según tus necesidades */
  border-bottom: 2px solid var(--cambridge);
}


.log {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 2;
  color: var(--cambridge);
}

.context-menu {
  flex-grow: 8;
  display: flex;
  align-items: center;
  justify-content: end;
}

.menu {
  list-style: none;
  padding: 0%;
  margin: 0%;
  display: flex;
  margin-right: 20px;
}

.menu li {
  margin-left: 25px;
}

.menu a {
  position: relative;
  color: var(--snow);
  font-size: 1.1vw;
  font-weight: bold;
  display: block;
  border-radius: 5px;
}

.menu a::before {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background-color: var(--claret);
  position: absolute;
  bottom: -.25rem;
  right: 0;
  transition: right .4s, width .4s, left .4s;
}

.menu a:hover::before {
  width: 100%;
  left: 0;
}

.menu a:hover {
  color: var(--claret);
}

#checkmenu {
  display: none;
}

.toggle-menu {
  display: none;
}

.toggle2 {
  position: relative;
  width: 40px;
  height: 38px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition-duration: .5s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: var(--cambridge);
  border-radius: 6px;
}

#bar5 {
  transition-duration: .8s;
}

#bar4,
#bar6 {
  width: 80%;
}

#checkmenu:checked+.toggle2 .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkmenu:checked+.toggle2 #bar5 {
  transform: scaleX(0);
  transition-duration: .5s;
}

#checkmenu:checked+.toggle2 #bar4 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: .5s;
}

#checkmenu:checked+.toggle2 #bar6 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: .5s;
}

#checkmenu:checked+.toggle2 {
  transition-duration: .5s;
  transform: rotate(180deg);
}


/* botones */
.btn-primary {
  min-block-size: 1vh;
  min-width: fit-content;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  text-transform: uppercase;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: relative;
  cursor: pointer;
  background: -webkit-gradient(linear, left top, right top, color-stop(-12.74%, var(--cambridge)), color-stop(56.76%, var(--night)));
  background: linear-gradient(90deg, var(--cambridge) -12.74%, var(--night) 56.76%);
  border: 2px solid var(--snow);
  color: var(--snow);
  font-weight: 600;
  transition: all 300ms;
}

.btn-primary::before {
  content: '';
  width: 4px;
  height: 28px;
  background: var(--night);
  border: 2px solid var(--snow);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  bottom: -7px;
  left: 4px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.btn-primary:after {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -2px;
  border-top: 15px solid transparent;
  border-left: 15px solid var(--snow);
}

.btn-primary:hover {
  border-color: var(--cambridge);
  color: var(--cambridge);
}


.link-banner {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  border: 2px solid var(--cambridge);
  text-transform: uppercase;
  color: var(--cambridge);
  text-decoration: none;
  font-weight: 600;
}

.link-banner::before {
  content: '';
  position: absolute;
  top: 6px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% - 12px);
  background-color: var(--night);
  transition: 0.3s ease-in-out;
  transform: scaleY(1);
}

.link-banner:hover::before {
  transform: scaleY(0);
}

.link-banner::after {
  content: '';
  position: absolute;
  left: 6px;
  top: -2px;
  height: calc(100% + 4px);
  width: calc(100% - 12px);
  background-color: var(--night);
  transition: 0.3s ease-in-out;
  transform: scaleX(1);
  transition-delay: 0.5s;
}

.link-banner:hover::after {
  transform: scaleX(0);
}

.link-banner span {
  position: relative;
  z-index: 3;
}

.btn-banner {
  background-color: none;
  text-decoration: none;
  background-color: var(--night);
  border: none;
}

/* home */
#home {
  display: flex;
  height: 94vh;
}

.title-home {
  color: var(--snow);
}

.home-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-basis: 60%;
  flex-shrink: 1;
}

.container-titles {
  width: 80%;
}

.home-banner {
  flex-basis: 40%;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons-banner {
  margin-top: 10px;
  display: flex;
  justify-content: left;
  gap: 20px;
}

.info-numeric {
  display: flex;
  text-align: center;
  margin-top: 60px;
  gap: 10px;
}

.info-numeric div {
  padding: 10px;
  width: 33%;
}

.info-numeric p {
  font-size: 1.5vw;
}

.info-numeric span {
  color: var(--snow);
  font-size: 0.9vw;
}

.exp-num {
  border-left: 2px solid var(--snow);
  border-right: 2px solid var(--snow);
}

.card-banner {
  position: relative;
  width: 15vw;
  height: 22vw;
  background-color: var(--cambridge);
  border-radius: 20px;
  box-shadow: 0 0 30px 10px var(--snow);
  overflow: hidden;
}

.top-card-banner {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 65%;
  transition: height 0.3s ease;
  background-color: var(--snow);
}

.bottom-card-banner {
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 35%;
  transition: height 0.3s ease;
  background-color: var(--cambridge);
}

.bottom-card-banner::before {
  content: "";
  position: absolute;
  bottom: 35%;
  height: 4vw;
  width: 13vw;
  transition: bottom 0.3s ease;
  border-bottom-left-radius: 20px;
  box-shadow: 0 30px 0 0 var(--cambridge);
}

.card-content-banner {
  padding-top: 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--snow);
}

.card-banner-title {
  font-weight: 700;
  font-size: 1.3vw;
}

.card-banner-txt {
  font-size: 0.8vw;
}

.card-content-banner .btn-primary {
  margin-top: 15%;
}

.card-content-banner .btn-primary::before {
  background: var(--cambridge);
}

.card-banner:hover {
  box-shadow: 0 0 50px 10px var(--snow);
}

.card-banner:hover .top-card-banner {
  height: 35%;
  transition: height 0.3s ease;
}

.card-banner:hover .bottom-card-banner {
  height: 65%;
  transition: height 0.3s ease;
}

.card-banner:hover .bottom-card-banner::before {
  bottom: 65%;
  transition: bottom 0.3s ease;
}

.card-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Esta propiedad ajusta la imagen para que cubra todo el contenedor sin deformarse */
  display: block;
  z-index: 2;
}

/* servicio */

#services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 99vh;
  padding-left: 30px;
  padding-right: 30px;
}

.services-title {
  display: flex;
  text-align: center;
  justify-content: center;
}

.services-title h2 {
  color: var(--claret);
}

.services-container {
  margin-top: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 80px;
}

.animation-eyes {
  display: flex;
  justify-content: center;
  text-align: center;
}

.eyes-service {
  position: relative;
  width: 108px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.eyes-service::after,
.eyes-service::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: var(--snow);
  background-image: radial-gradient(circle 14px, var(--night) 100%, transparent 0);
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: eyeMove 10s infinite, blink 10s infinite;
}

@keyframes eyeMove {

  0%,
  10% {
    background-position: 0px 0px;
  }

  13%,
  40% {
    background-position: -15px 0px;
  }

  43%,
  70% {
    background-position: 15px 0px;
  }

  73%,
  90% {
    background-position: 0px 15px;
  }

  93%,
  100% {
    background-position: 0px 0px;
  }
}

@keyframes blink {

  0%,
  10%,
  12%,
  20%,
  22%,
  40%,
  42%,
  60%,
  62%,
  70%,
  72%,
  90%,
  92%,
  98%,
  100% {
    transform: scaleY(1);
    /* Estado normal de los ojos */
  }

  11%,
  21%,
  41%,
  61%,
  71%,
  91%,
  99% {
    transform: scaleY(0.4);
    /* Simulación de parpadeo */
  }
}

#products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 99vh;
  padding-left: 20px;
  padding-right: 20px;
}

.title-products {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.title-products h2 {
  color: var(--claret);
}

.product {
  display: flex;
  margin-top: 15px;
  justify-content: center;
  gap: 40px;
}

/* Contacto */

#contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 99vh;
  padding-left: 30px;
  padding-right: 30px;
}

#contact h2 {
  text-align: center;
  color: var(--claret);
}

#contact p {
  text-align: center;
}

.contact-zone {
  padding: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 23vw;
  height: 27vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  border: 1px solid var(--jtc);
  background: linear-gradient(to right, var(--night), var(--jtc));
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-container form .descr {
  font-size: 1.2vw;
  font-weight: 500;
  color: var(--snow);
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}


.input,
textarea {
  margin: 1em 0 1em 0;
  position: relative;
}

.input input,
textarea,
select {
  font-size: 100%;
  padding: 0.7em;
  outline: none;
  color: var(--snow);
  border: none;
  border-bottom: 2px solid var(--snow);
  background: transparent;
  border-radius: none;
  width: 100%;
  resize: none;
}

.input label {
  font-size: 100%;
  position: absolute;
  left: 0;
  color: var(--snow);
  padding: 0.7em;
  margin-left: 0.1em;
  pointer-events: none;
  transition: all 0.5s ease;
  text-transform: uppercase;
}

.input :is(input:focus, input:valid)~label {
  transform: translateY(-50%) scale(.9);
  margin: 0em;
  padding: 0.4em;
  background: transparent;
}

.input textarea:focus~label,
.input textarea:valid~label {
  transform: translateY(-50%) scale(.9);
  margin: 0em;
  padding: 0.4em;
  background: transparent;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: var(--jtc);
}

.form button {
  color: var(--snow);
  font-size: 15px;
  align-self: flex-start;
  padding: 0.6em;
  border: none;
  cursor: pointer;
  margin-bottom: 50px;
  background: transparent;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.form button:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--snow);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.form button:hover:before {
  opacity: 0.2;
}

.form button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px var(--night);
}

.contact-info {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.contact-data p {
  font-size: 14px;
}

/*nesletter*/

.newsletter {
  position: relative;
  width: 23vw;
  height: 27vw;
  display: flex;
  background: linear-gradient(to right, var(--jtc), var(--night));
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 13px;
  border: 1px solid var(--jtc);
}

.form-news {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.news-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: var(--jtc);
  box-shadow: 0px 0.5px 0.5px var(--night), 0px 1px 0.5px var(--jtc);
  border-radius: 5px;
}

.news-icon i {
  color: var(--snow);
  font-size: 1.9vw;
}

.news-note {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.news-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 24px;
  color: var(--snow);
}

.news-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 18px;
  color: var(--lowcambridge);
}

.news-input_field {
  width: 100%;
  height: 42px;
  padding: 0 0 0 12px;
  border-radius: 5px;
  border: 1px solid var(--jtc);
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.news-input_field:focus {
  color: var(--snow);
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px var(--night);
  background-color: transparent;
}

.form-news .btn-primary {
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-12.74%, var(--claret)), color-stop(56.76%, var(--night)));
  background: linear-gradient(90deg, var(--claret) -12.74%, var(--night) 56.76%);
}

.form-news .btn-primary:hover {
  border-color: var(--claret);
  color: var(--claret);
}

.moji {
  width: 25vw;
}

/*Copy*/
.copy {
  display: flex;
  justify-content: center;
  width: 100%;
}

.copy h4 {
  color: var(--lowcambridge);
  border-top: 1px solid var(--snow);
  border-bottom: 1px solid var(--snow);
  padding-top: 5px;
  padding-bottom: 5px;
}

/* layout */

.mudkip {
  flex-basis: 40%;
  flex-shrink: 1;
}

.card {
  width: min(25vw, 100%);
  background-color: var(--snow);
  text-align: center;
  border-top-left-radius: 4rem;
  border: 2px solid var(--jtc);
  position: relative;
}

.card::before {
  content: "";
  position: absolute;
  height: 1.7vw;
  width: 7vw;
  background-color: var(--claret);
  top: 1vw;
  right: -2.5px;
  -webkit-clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}

.card__body {
  padding: 1.5rem 1rem;
  max-width: 85%;
  margin: auto;
}

.card__icon i {
  font-size: 2.5vw;
}

.card__title {
  font-weight: 800;
  color: var(--night);
  font-size: 1.2vw;
  margin-block: 1.5rem 0.75rem;
}

.card__paragraph {
  color: var(--night);
  font-size: 0.8vw;
}

.card__ribbon {
  margin-top: 1.5rem;
  display: grid;
  place-items: center;
  height: 3.5vw;
  background-color: var(--claret);
  position: relative;
  width: 110%;
  left: -5%;
  top: 10px;
  position: relative;
  border-radius: 0 0 2rem 2rem;
}

.card__ribbon::after,
.card__ribbon::before {
  content: "";
  position: absolute;
  width: 5vw;
  aspect-ratio: 1/1;
  bottom: 100%;
  z-index: -2;
  background-color: var(--jtc);
}

.card__ribbon::before {
  left: 0;
  transform-origin: left bottom;
  transform: rotate(45deg);
}

.card__ribbon::after {
  right: 0;
  transform-origin: right bottom;
  transform: rotate(-45deg);
}

.card__ribbon-label {
  display: block;
  width: 6vw;
  aspect-ratio: 1/1;
  background-color: var(--snow);
  position: relative;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 8px solid var(--claret);
  display: grid;
  place-items: center;
  font-weight: 900;
  line-height: 1;
  font-size: 1.5rem;
}

.card__ribbon-label::before,
.card__ribbon-label::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 50%;
}

.card__ribbon-label::before {
  right: calc(100% + 4px);
  border-bottom-right-radius: 20px;
  box-shadow: 5px 5px 0 var(--claret);
}

.card__ribbon-label::after {
  left: calc(100% + 4px);
  border-bottom-left-radius: 20px;
  box-shadow: -5px 5px 0 var(--claret);
}


.flip-card {
  background-color: transparent;
  width: 20vw;
  height: 14vw;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 3px 10px 0 var(--snow);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid var(--jtc);
  border-radius: 1rem;
}

.flip-card-front {
  background: linear-gradient(120deg, var(--cambridge) 60%, var(--lowcambridge) 88%,
      var(--jtc) 40%, var(--claret) 48%);
  color: var(--night);
}

.flip-card-front h3 {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--snow);
}

.flip-card-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 1rem;
  filter: brightness(60%);
}

.flip-card-back {
  background: linear-gradient(120deg, var(--night) 30%, var(--lowcambridge) 88%,
      var(--lowcambridge) 40%, var(--lowcambridge) 78%);
  color: var(--snow);
  transform: rotateY(180deg);
  padding: 10px;
}

.flip-card-back h3 {
  color: var(--snow);
}

/*Ventana Modal*/
/* Modal container */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s ease;
}

/* Hidden class to hide the modal */
.modal.hidden {
  opacity: 0;
  pointer-events: none;
}

/* Modal content */
.modal-content {
  box-shadow: var(--night) 5px 10px 50px, var(--night) -5px 0px 250px;
  background: linear-gradient(to right, var(--night), var(--alerts));
  border-radius: 8px;
  padding: 20px;
  width: 950px;
  text-align: center;
  position: relative;
}

.team-container {
  display: flex;
  gap: 15px;
}

.team-column {
  display: flex;
  flex-direction: column;
}

.team-member-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
}

.team-member-socials a {
  margin-right: 5px;
  color: var(--lowcambridge);
  transition: color 0.3s;
  display: inline-block;
}

.team-member-socials a:hover {
  animation: zoom 0.3s forwards;
  color: var(--cambridge);
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

/* Close button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: var(--snow);
}

.popup .close-modal{
  color: var(--night);
}

/* loader */
@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.loader {
  position: fixed;
  width: 90px;
  height: 90px;
  transform: rotate(45deg);
  z-index: 10;
}

.loader-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  margin: .2px;
  border-radius: 0px;
  background: var(--snow);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
}

.hiden {
  display: none;
}

.loader-square:nth-of-type(0) {
  animation-delay: 0s;
}

.loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
}

.loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
}

.loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
}

.loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
}

.loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
}

.loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
}

.loader-square:nth-of-type(7) {
  animation-delay: -10s;
}

/* pop-up emergente*/

/* Popup */
.popup {
  position: fixed;
  z-index: 8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(10deg, var(--night), var(--alerts));
  margin: 15% auto;
  padding: 20px;
  border: 1px solid var(--lowcambridge);
  border-radius: 10px;
  width: 25%;
}

.popup-content .success-icon {
  font-size: 65px;
  color: var(--success);
  margin-bottom: 20px;
}

.popup-content .danger-icon {
  font-size: 65px;
  color: var(--danger);
  margin-bottom: 20px;
}

/* Menu responsivo*/

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* medias queries */
@media (max-width: 800px) {
  .menu {
    display: none;
  }

  .menu.show {
    display: block;
    position: fixed;
    top: 50px;
    left: 0vw;
    background: rgb(36, 59, 85, 0.7); 
    width: 200px;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-top: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 2px solid var(--cambridge);
    animation: slideIn 0.3s forwards;
  }

  .menu.hide {
    animation: slideOut 0.3s forwards; 
  }

  .menu a {
    font-size: 19px;
    line-height: 40px;
  }

  .toggle-menu {
    display: block;
  }

  .log {
    font-size: 27px;
  }

  #home {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  #home h1{
    font-size: 22px;
    margin-bottom: 10px;
    text-align: justify;
  }

  #home p{
    font-size: 19px;
    margin-bottom: 25px;
  }

  #home button, #home a{
    font-size: 15px;
  }
  
  #home span {
    font-size: 15px;
  }

  .home-banner{
    display: none;
  }

  #services {
    height: auto;
    padding: 60px;
  }

  #services h2 {
    font-size: 21px;
    text-align: left;
  }

  #services p {
    font-size: 19px;
    text-align: left;
    margin-bottom: 30px;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .card {
    width: 80%;
    margin-bottom: 20px;
  }

  .card i{
    font-size: 50px;
  }

 .card::before {
    height: 30px;
    width: 120px;
  }

  .card__ribbon{
    height: inherit;
  }

  .card__ribbon-label{
    width: 70px;
  }

  #products {
    height: auto;
    flex-direction: column;
    margin-bottom: 20px;
  }

  #products h2 {
    font-size: 21px;
    text-align: left;
  }

  #products h3 {
    font-size: 21px;
  }

  #products p {
    font-size: 19px;
    text-align: left;
    margin-bottom: 30px;
  }

  .product{
    flex-direction: column;
    align-items: center;
  }

  .flip-card {
    width: 80%;
    height: 300px;
}

  #contact {
    height: auto;
  }

  #contact h2 {
    font-size: 21px;
    text-align: left;
  }

  #contact p {
    font-size: 19px;
    text-align: left;
    margin-bottom: 30px;
  }

  .contact-zone {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    display: none;
  }

  .form-container {
    width: 70%;
    height: 500px;
  }

  .form {
    width: 90%;
  }

  .form div {
    font-size: 19px!important;
  }

  .newsletter {
    width: 70%;
    height: 300px;
  }

  .news-icon {
    width: 80px;
    height: 80px;
  }

  .news-icon i {
    font-size: 35px;
  }

  .news-title {
    font-size: 21px;
  }

  .news-subtitle {
    font-size: 16px;
  }

  .copy h4 {
    font-size: 16px;
  }
  
  .modal {
    width: 100%;
    overflow-y: scroll;
    align-items: baseline;
  }

  .modal-content {
    width: 80%;
    height: fit-content;
  }

  .modal-content h2 {
    font-size: 22px;
  }

  .modal-content .team-container {
    flex-direction: column;
  }

  .team-member-image {
    width: 110px;
    height: 110px;
  }

  .team-member-socials a {
    font-size: 20px;
  }

  .team-member-socials a:hover {
    animation: none;
  }

  .team-column h3 {
    font-size: 20px;
  }

  .team-column p {
    font-size: 16px;
  }

  .popup h3 {
    font-size: 22px;
    text-align: center;
  }

  .popup p {
    font-size: 19px;
    text-align: center;
  }

  .popup-content {
    width: 60%;
  }
}

@media (max-width: 500px){
  .buttons-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #services {
    height: auto;
    padding: 25px;
  }

  .info-numeric{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
  }

  .info-numeric div {
    width: 100%;
    text-align: center;
  }

  .exp-num {
    border-top: 2px solid var(--snow);
    border-bottom: 2px solid var(--snow);
    border-left: inherit;
    border-right: inherit;
    padding-bottom: 25px!important;
  }

  .flip-card-back p{
    font-size: 16px!important;
  }

  .form-container {
    width: 95%;
    height: 500px;
  }

  .newsletter {
    width: 95%;
    height: 400px;
  }

  .card::before {
    height: 25px;
    width: 70px;
  }

  
}